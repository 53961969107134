<template>
  <div class="student_list_home_page">
    <div class="table_page">
      <div class="table_top">
        <div class="title">已配课未完善信息学员</div>
        
      </div>

      <el-table
        header-align="center"
        stripe
        header-cell-class-name="header_row_style"
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column v-if="isCheck" type="selection" width="55">
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="false"
          :align="item.align || 'center'"
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :width="item.width"
          :label="item.label"
        >
        </el-table-column>

        <el-table-column align="center" width="150" label="操作">
          <template slot-scope="scope">
            <div class="table_btns">
              <div class="table_btn" @click="handleEdit(scope.row, 'view')">
                查看
              </div>
              <div class="table_btn" @click="handleEdit(scope.row, 'edit')">
                编辑
              </div>
              <div class="table_btn" @click="handleDel(scope.row)">操作</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <comdialog
      :list="fromList"
      ref="comdialog"
      :params.sync="stuInfo"
      :edit="alertStatus"
      :title="
        alertStatus === 'edit'
          ? '编辑学生'
          : alertStatus === 'add'
          ? '新增学生'
          : '查看学生'
      "
      @onSubmit="onSubmit"
      @close="comdialogClose"
      @changeCardType = "changeStuCardType"
    ></comdialog>
    <el-dialog
      class="mesDialog"
      title="信息"
      :visible.sync="moreHandleInfo"
      width="30%"
      @before-close="handleMoreInfoClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span class="f16">请确认您的操作</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="w60 h30" type="primary" @click="handleMoreInfo(1)"
          >转学</el-button
        >
        <el-button class="w60 h30" @click="handleMoreInfo(2)">毕业</el-button>
        <!-- todo 删除操作缺失-->
        <el-button class="w60 h30" @click="deleteStudent">删除</el-button>
      </span>
    </el-dialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import comdialog from "@/components/admin/editDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "withClassNoFullInfo",
  components: {
    comdialog,
    comPagination,
  },
  data() {
    var checkName = (rule,value,callback)=>{
      if (!this.common.textNumberCheck(value,25)) {
        callback(new Error("请输入正确格式姓名"));
      }else{
        callback();
      }
    };
    var checkIdCard = (rule,value,callback) => {
      if(this.stuCardType == 0){
        if(value){
          if(!this.common.validateIdCard(value)){
            callback(new Error("请输入正确格式身份证号"));
          }else{
            callback();
          }
        }else{
          callback();
        }
      }else{
        callback();
      }
    };
    var checkParentName = (rule,value,callback)=>{
      if(value){
        if (!this.common.textNumberCheck(value,25)) {
          callback(new Error("请输入正确格式家长姓名"));
        }else{
          callback();
        }
      }else{
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      if(!(value)){
        callback();
      }else{
        if (!this.common.isMobile(value)) {
          callback(new Error("请输入正确格式家长联系电话"));
        }else{
          callback();
        }
      }
    };
    return {
      //班级列表
      columns: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sexName",
          label: "性别",
        },
        {
          prop: "gradeName",
          label: "班级",
        },
        {
          prop: "integral",
          label: "学分",
        },
        {
          prop: "idCard",
          label: "身份证/护照",
        },
        {
          prop: "parentName",
          label: "家长姓名",
        },
        {
          prop: "phone",
          label: "家长电话",
        },
        // {
        //   prop: 'leve',
        //   label: '成就等级',
        // },
      ],
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      // 选中数据
      isCheck: false,
      multipleSelection: [],

      // 学生信息弹窗
      stuInfoShow: true,
      stuEdit: true,
      classOptions: [],
      stuCardType:0,
      fromList: [
        {
          prop: "name",
          type: "text",
          label: "学生姓名",
          rules: [
            { required: true, message: "请输入姓名", trigger: "blur" },
            { validator: checkName, trigger: "blur" }
          ],
        },
        {
          prop: "idCardType",
          type: "radio",
          label: "证件类型",
          required: false,
          options: [
            {
              label: "身份证",
              value: 0,
            },
            {
              label: "护照",
              value: 1,
            },
            // {
            //   label: '其他证件',
            //   value: 2,
            // },
          ],
        },
        {
          prop: "idCard",
          type: "text",
          label: "证件号",
          required: false,
          rules: [{ validator: checkIdCard, trigger: "blur" }],
        },
        {
          prop: "parentName",
          type: "text",
          label: "家长姓名",
          required: false,
          rules: [{ validator: checkParentName, trigger: "blur" }],
        },
        {
          prop: "photo",
          type: "upload",
          label: "学员照片",
          required: false,
        },
        {
          prop: "birthday",
          type: "date",
          label: "学员出生日期",
          rules: [{ required: true, message: '请选择学员出生日期', trigger: "blur" }],
        },
        {
          prop: "gradeId",
          type: "select",
          label: "学员所属班级",
          options: [],
          rules: [{ required: true, message: '请选择学员所属班级', trigger: "blur" }],
        },
        {
          prop: "sex",
          type: "radio",
          label: "学员性别",
          required: true,
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        {
          prop: "phone",
          type: "number",
          label: "家长联系电话",
          required: false,
          rules: [{ validator: checkPhone, trigger: "blur" }],
        },
      ],
      stuInfo: {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: 3,
        sex: "",
        phone: "",
      },
      selectValue: "", //批量分班值
      options: [],

      // 弹窗状态  新增/编辑/查看
      alertStatus: "",
      // 更多操作显示
      moreHandleInfo: false,

      // 更多操作已选数据
      tabMoreInfo: {},
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getStuInfo();
      this.getClassInfo();
    },
    // 获取学生信息
    getStuInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.admin.findNoFullInfoStudent(this.params, data).then((res) => {
        this.params.total = res.data.total;

        this.list = res.data.list.map((item) => {
          return {
            ...item,
            sexName: item.sex == 1 ? "男" : "女",
          };
        });

        // console.log(res)
      });
    },
    // 获取学校信息
    getClassInfo() {
      this.api.admin.findGradesDetails().then((res) => {
        let arr = res.data.map((item) => {
          return { label: item.atteGrade.name, value: item.atteGrade.id };
        });
        this.options = arr;
        this.fromList.forEach((item) => {
          if (item.prop === "gradeId") {
            item.options = arr;
          }
        });
      });
    },
    // 选项变化
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    // 多选禁用
    // selectEnable(row) {
    //   console.log(row);
    //   // 0 需要用常量判断
    //   if (row.status === 0) {
    //     return true;
    //   } else {
    //     return false; // 不禁用
    //   }
    // },
    // 非公益配课 取消操作
    handleConfigCancle() {
      this.multipleSelection = [];
      this.isCheck = false;
    },
    onSubmit(val) {
      // 新增
      val.name = val.name.trim();
      val.idCard = val.idCard.trim();
      val.parentName = val.parentName.trim();
      val.phone = val.phone.trim();
      if (this.alertStatus === "add") {
        val.birthday = this.common.dateFormat(val.birthday);
        val.photo = val.photo.split(".com")[1];
        this.api.admin.addStudent(val).then((res) => {
          if (res.flag) {
            this.getStuInfo();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialog.isClose();
        });
      } else if (this.alertStatus === "edit") {
        val.birthday = this.common.dateFormat(val.birthday);
        val.photo = val.photo.split(".com")[1];
        val.sex = parseInt(val.sex);
        this.api.admin.updateStu(val, val.id).then((res) => {
          console.log(res);
          if (res.flag) {
            this.getStuInfo();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialog.isClose();
        });
      }
    },
    //打开编辑弹窗
    addStu() {
      this.$refs.comdialog.isOpen();
      this.alertStatus = "add";
      this.stuInfo = {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: "",
        sex: 1,
        phone: "",
      };
    },
    handleEdit(val, type) {
      this.$refs.comdialog.isOpen();
      this.alertStatus = type;
      this.stuInfo = {
        name: val.name,
        idCardType: val.idCardType,
        idCard: val.idCard,
        parentName: val.parentName,
        photo: this.constant.URL_admin + val.photo,
        birthday: val.birthday,
        gradeId: val.gradeId,
        sex: parseInt(val.sex),
        phone: val.phone,
        id: val.id,
      };
      if(type == "view"){
        this.stuInfo.idCardType = val.idCardType == "0"
              ? "身份证"
              : val.idCardType == "1"
              ? "护照"
              : "其他证件";
        this.stuInfo.sex = val.sex == "1" ? "男" : "女";
      }
    },
    //关闭编辑弹窗
    comdialogClose() {
      this.stuInfo = {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: "",
        sex: 0,
        phone: "",
      };
      console.log(this.stuInfo, this.$refs.comdialog);
    },
    // 打开更多操作弹窗 赋值已选数据
    handleDel(value) {
      this.moreHandleInfo = true;
      this.tabMoreInfo = value;
    },
    // 更多操作关闭时清空已选数据
    handleMoreInfoClose() {
      this.tabMoreInfo = {};
    },
    // 转学/毕业/删除
    handleMoreInfo(val) {
      if (val !== this.constant.stuStatus.atSchool) {
        let data = {
          type: val,
          beforeGrade: this.tabMoreInfo.gradeId,
          gradeId: 0,
        };
        this.api.admin.changeSchool(data, this.tabMoreInfo.id).then((res) => {
          if (res.flag) {
            this.moreHandleInfo = false;
            this.$notice({
              type: "success",
              message: `学生${
                val === this.constant.stuStatus.goSchool ? "转学" : "毕业"
              }成功`,
            }).isShow();
            //重新获取学生列表
            this.init();
            this.$router.go(0);
          }
        });
      }
    },
    // 删除学生
    deleteStudent() {
      this.$confirm("此操作将永久删除该学员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.api.admin.updateDeleteStatus(this.tabMoreInfo.id).then((res) => {
            this.moreHandleInfo = false;
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "删除成功!",
              }).isShow();
              this.getStuInfo();
            } else {
              this.$notice({
                type: "warn",
                message: "删除失败!",
              }).isShow();
            }
          });
        })
        .catch(() => {
          this.$notice({
            type: "info",
            message: "已取消删除",
          }).isShow();
        });
    },
    // 批量分班
    updataByList() {
      if (!this.multipleSelection.length) {
        this.$notice({
          message: "请先选择批量分班的学生",
        }).isShow();
        return;
      }
      if (!this.selectValue) {
        this.$notice({
          message: "请选择批量设置的班级",
        }).isShow();
        return;
      }
      let str = this.multipleSelection.map((item) => item.id);
      let data = {
        students: str.join(","),
        grade: this.selectValue,
      };
      this.api.admin.updataByList(data).then((res) => {
        console.log(res);
        this.$notice({
          type: "success",
          message: "批量分班操作成功",
        }).isShow();
        this.selectValue = "";
        this.multipleSelection = [];
        this.getStuInfo();
      });
    },

    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.multipleSelection = [];
      this.getStuInfo();
    },
    //添加学生,切换证件类型
    changeStuCardType(val) {
      this.stuCardType = val;
    }
  },
};
</script>

<style lang="scss" scoped>
.student_list_home_page {
  .table_top {
    @include flex(row, space-between, center);
    margin-bottom: 0.2rem;

    .title {
      font-size: 34px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #cc1a30;
    }
  }

  .top_block {
    flex: 1;
    @include flex(row, flex-end, center);

    .title {
      font-size: 18px;
      font-weight: 400;
      color: black;
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss">
.top_block {
  .el-input__inner {
    height: 60px;
    width: 250px;
    font-size: 20px;
  }
  .el-scrollbar__wrap {
    margin: 0;
  }
}
</style>
